<template>
  <default-layout>
    <div class="hero-section">
      <img :src="require('@/assets/1.png')" alt="Hero Image" class="hero-image"/>
      <div class="hero-text">
        <h1>Advancing creativity with artificial intelligence.</h1>
        <button class="cta-button">View Careers</button>
      </div>
    </div>

    <section class="intro-section">
      <div class="intro-text">
        <p>
          We believe that AI systems applied to audiovisual content will forever change art and creativity. Since 2016, have been at the forefront of AI media, making state-of-the-art technologies accessible, controllable, and all empowering.
          <!-- Since 2016, we have been at the forefront of AI media, ensuring that the future of content creation is accessible, controllable, and empowering for everyone. -->
        </p>
      
        <p>
          AI-powered translation tools help promote mutual understanding and respect on a global scale by breaking down language barriers.
          By enabling seamless communication and collaboration, these tools promote empathy and shared knowledge, paving the way for inclusive dialogue and a global audience.   
        </p>

      </div>
    </section>

    <section class="team-section">
      <img :src="require('@/assets/2.png')" alt="Team Image" class="team-image"/>
      <p class="team-info">
        We have offices in New York and Seattle, with team members working remotely from all over the world.
      </p>
    </section>

    <section class="roles-section">
      <h2>Open Roles</h2>
      <div class="roles-table">
        <table>
          <thead>
            <tr>
              <th>Position</th>
              <th>Department</th>
              <th>Location</th>
              <th>Apply</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Engineering Manager, Machine Learning</td>
              <td>Research</td>
              <td>Remote / New York</td>
              <td><a href="#">Apply</a></td>
            </tr>
            <tr>
              <td>Head of Sales</td>
              <td>Finance & Sales</td>
              <td>Remote</td>
              <td><a href="#">Apply</a></td>
            </tr>
            <tr>
              <td>Member of Technical Staff, Applied Research</td>
              <td>Research</td>
              <td>Remote / New York</td>
              <td><a href="#">Apply</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout";

export default {
  components: { DefaultLayout },
};
</script>

<style scoped>
.hero-section {
  position: relative;
  text-align: center;
}

.hero-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}

.hero-text h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.cta-button {
  padding: 12px 30px;
  font-size: 16px;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.intro-section {
  padding: 50px 20px;
  text-align: center;
}

.intro-text p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  line-height: 1.6;
}

.team-section {
  text-align: center;
  padding: 50px 20px;
}

.team-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.team-info {
  font-size: 1.2rem;
  margin-top: 20px;
}

.roles-section {
  padding: 50px 20px;
  text-align: center;
}

.roles-table table {
  width: 100%;
  border-collapse: collapse;
}

.roles-table th, td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.roles-table th {
  background-color: #f4f4f4;
}

.roles-table a {
  color: #007bff;
  text-decoration: none;
}

.roles-table a:hover {
  text-decoration: underline;
}
</style>
