<template>
  <div class="row">
    <div class="col">
      <!-- Video Submission Form -->
      <h3>Submit a Video for Translation</h3>
      <form @submit.prevent="handleSubmit">
        <div class="form-group spaced-element">
          <label for="videoLink">Video Link</label>
          <input type="url" class="form-control" v-model="videoLink" required placeholder="Enter video URL">
        </div>

        <div class="form-group spaced-element">
          <label for="audioOnly">Audio Only:</label>
          <select v-model="audioOnly" class="form-control">
            <option :value="false">No</option>
            <option :value="true">Yes</option>
          </select>
        </div>

        <!-- Language Selection with Checkboxes -->
        <div class="form-group spaced-element">
          <label>Select Target Languages:</label>
          <br>
          <div class="checkbox-group">
            <div v-for="lang in languages" :key="lang" class="form-check form-check-inline">
              <input 
                class="form-check-input" 
                type="checkbox" 
                :value="lang" 
                v-model="selectedLanguages"
              >
              <label class="form-check-label">{{ lang }}</label>
            </div>
          </div>
        </div>
        
        <!-- Submit button with added padding below it -->
        <div class="spaced-button">
          <button type="submit" class="btn btn-success">Submit</button>
        </div>
      </form>
      
      <!-- Task List -->
      <h3>Tasks:</h3>
      <p class="small-text">A link to your video will be emailed</p>
      <ul class="list-group spaced-element">
        <li v-for="(task, index) in tasks" :key="index" class="list-group-item">
          <strong>Video:</strong> {{ task.videoLink }} <br/>
          <strong>Audio Only:</strong> {{ task.audioOnly ? 'Yes' : 'No' }} <br/>
          <strong>Language(s):</strong> {{ task.language }} <br/>
          <strong>Status:</strong> In Progress
        </li>
      </ul>

      <!-- Gap before Subscribed Account Section -->
      <div class="gap"></div>

      <!-- Boost Product Section -->
      <div class="boost-product-section spaced-element">
        <h3>Priority Processing (faster compute):</h3>
        <div class="button-group">
          <button 
            class="btn btn-warning d-inline-block"
            :disabled="!boostProduct || isLoadingBoost"
            @click="purchaseBoost"
          >
            {{ isLoadingBoost ? "Processing..." : "Purchase Boost" }}
          </button>

          <button @click="togglePopup" class="btn btn-info info-button d-inline-block">i</button>
        </div>

        <!-- In-screen pop-up -->
        <div v-if="showPopup" class="popup-overlay" @click="closePopup">
          <div class="popup-content" @click.stop>
            <p>
            <b>Priority Processing for Your Videos:</b>
            <br>
            <br>
            <small> 
            Priority process queues ("Lines") ensure fair access for everyone and protect against misuse. 
            <br>
            The regular queue currently is just under 24h on average during weekdays, and is known to be faster during weekends.
            
            </small> 
            </p>

          </div>
        </div>
      </div>
    </div>

    
      <!-- Subscribed Account Section with smaller text -->
      <h2 class="subscribed-account-title">Account Information:</h2>

      <!-- Boost Status (if boosted) -->
      <div v-if="boosted" class="alert alert-info">
        <strong>Boosted</strong>
      </div>


      <!-- Subscription Info -->
      <div class="alert alert-warning" v-if="subscription.cancel_at_period_end">
        This subscription will cancel at the end of the period.
      </div>
      <p class="subscription-text">
        Current period start:
        {{
          new Date(
            subscription.current_period_start.seconds * 1000
          ).toLocaleString()
        }}
      </p>
      <p class="subscription-text">
        Current period end:
        {{
          new Date(
            subscription.current_period_end.seconds * 1000
          ).toLocaleString()
        }}
      </p>

      <button class="btn btn-primary billing-portal-button" 
              :disabled="isLoading"
              @click="openCustomerPortal">
        {{ isLoading ? "Loading..." : "Open my billing portal" }}
      </button>

      
  </div>
  
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore, doc, getDoc, collection, getDocs, query, where, addDoc, onSnapshot, updateDoc, arrayUnion } from "firebase/firestore"; // Added 'arrayUnion'
import { getCurrentUser } from "../firebase";

export default {
  props: ["subscription"],
  data() {
    return {
      isLoading: false,
      isLoadingBoost: false,
      videoLink: "",
      audioOnly: false,
      selectedLanguages: [],
      tasks: [],
      boosted: false, // Track boost status
      languages: ["Arabic", "Chinese (Simplified)", "Danish", "Dutch", "French", "German", "Hindi", "Indonesian", "Japanese", "Korean", "Philipino", "Russian", "Spanish", "Swedish", "Turkish", "Vietnamese", "Zulu"],
      boostProduct: null, // Store Boost product info
      selectedBoostPrice: null, // Store selected Boost price ID
      showPopup: false, // Controls pop-up visibility
    };
  },

  async created() {
    await this.loadUserTasks();
    await this.fetchBoostProduct();
    await this.checkBoostStatus(); // Check boost status on page load
  },

  methods: {
    togglePopup() {
      this.showPopup = !this.showPopup;
    },
    
    closePopup() {
      this.showPopup = false;
    },

    async openCustomerPortal() {
      this.isLoading = true;
      const functions = getFunctions();
      const functionRef = httpsCallable(functions, "ext-firestore-stripe-payments-createPortalLink");
      const { data } = await functionRef({
        returnUrl: window.location.origin,
      });
      window.location.assign(data.url);
    },

    async handleSubmit() {
      const newTask = {
        videoLink: this.videoLink,
        audioOnly: this.audioOnly,
        language: this.selectedLanguages.join(", "),
        createdAt: new Date(),
      };

      try {
        const user = await getCurrentUser();
        if (user) {
          const db = getFirestore();
          const customerRef = doc(db, "customers", user.uid);

          // Update the customer document with the new task
          await updateDoc(customerRef, {
            submissions: arrayUnion(newTask) // Use arrayUnion for adding tasks
          });
        }

        this.tasks.push(newTask);

        // Clear form fields
        this.videoLink = "";
        this.audioOnly = false;
        this.selectedLanguages = [];
      } catch (error) {
        console.error("Error saving task to Firestore: ", error);
      }
    },

    async loadUserTasks() {
      try {
        const user = await getCurrentUser();
        if (user) {
          const db = getFirestore();
          const customerRef = doc(db, "customers", user.uid);
          const customerSnap = await getDoc(customerRef);

          if (customerSnap.exists()) {
            const customerData = customerSnap.data();
            this.tasks = customerData.submissions || [];
          }
        }
      } catch (error) {
        console.error("Error fetching tasks from Firestore: ", error);
      }
    },

    async fetchBoostProduct() {
      const db = getFirestore();
      const productsRef = collection(db, "products");
      const productQuery = query(productsRef, where("name", "==", "Boost (skip the line)"));
      const productSnap = await getDocs(productQuery);

      productSnap.forEach(async (doc) => {
        const pricesRef = collection(db, "products", doc.id, "prices");
        const pricesSnap = await getDocs(pricesRef);

        this.boostProduct = {
          id: doc.id,
          ...doc.data(),
          prices: pricesSnap.docs.map((price) => {
            return {
              id: price.id,
              ...price.data(),
            };
          }),
        };

        if (this.boostProduct.prices.length > 0) {
          this.selectedBoostPrice = this.boostProduct.prices[0].id;
        }
      });
    },

    async purchaseBoost() {
      if (!this.selectedBoostPrice) return;

      this.isLoadingBoost = true;

      const db = getFirestore();
      const user = await getCurrentUser();
      const collectionRef = collection(db, "customers", user.uid, "checkout_sessions");

      // Create a checkout session for a one-time payment
      const docRef = await addDoc(collectionRef, {
        mode: "payment", // Use 'payment' mode for one-time purchases
        price: this.selectedBoostPrice,
        success_url: window.location.origin + '/account', // Redirect user after successful payment
        cancel_url: window.location.origin, // Redirect user if they cancel the payment
      });

      // Listen for the URL and redirect
      onSnapshot(docRef, async (snap) => {
        const { error, url } = snap.data();

        if (error) {
          console.error(`An error occurred: ${error.message}`);
          this.isLoadingBoost = false;
        }

        if (url) {
          window.location.assign(url); // Redirect to the Stripe checkout page

          // Mark the user as boosted in Firestore after successful payment
          const customerRef = doc(db, "customers", user.uid);
          await updateDoc(customerRef, {
            boosted: true
          });
        }
      });
    },

    // Check if the user has been boosted
    async checkBoostStatus() {
      const user = await getCurrentUser();
      if (user) {
        const db = getFirestore();
        const customerRef = doc(db, "customers", user.uid);
        const customerSnap = await getDoc(customerRef);

        if (customerSnap.exists() && customerSnap.data().boosted) {
          this.boosted = true;
        }
      }
    },
  },
};
</script>

<style scoped>
/* Styling for checkbox group */
.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.form-check-inline {
  display: inline-block;
  margin-right: 10px;
}

/* Add spacing between form fields and task list items */
.spaced-element {
  margin-bottom: 20px; /* Adds space below each form element */
}

/* Add padding between the Submit button and Tasks section */
.spaced-button {
  margin-bottom: 40px; /* Adds space below the submit button */
}

/* Smaller text for Subscribed Account section */
.subscribed-account-title {
  font-size: 1.2rem; /* Adjust the size as needed */
}

.subscription-text {
  font-size: 1rem; /* Adjust the size as needed */
}

/* Double the gap between Tasks and Subscribed Account */
.gap {
  margin-top: 2in; /* 2 inch gap */
}

/* Small text for subtext under Tasks */
.small-text {
  font-size: 0.8rem;
  color: gray;
  margin-left: 20px; /* Adds space to move the text right */
}

/* Styling for the billing portal button */
.billing-portal-button {
  width: 200px; /* Adjust the width as needed */
  margin-left: 10px; /* Center the button */
  margin-bottom: 30px;
}

/* Popup styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Dim background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Style for round info button */
.info-button {
  background-color: #4c7aaf; /* Button color */
  color: white; /* Text color */
  border-radius: 50%; /* Round button */
  width: 30px;
  height: 30px;
  display: flex; /* Flexbox for centering */
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
  font-size: 1.2rem; /* Adjust font size for better appearance */
  border: none; /* Remove border */
  padding: 0; /* Remove padding */
  line-height: 1; /* Adjust line-height */
  margin-left: 10px;
}
</style>
