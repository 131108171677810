<template>
  <default-layout>
    <!-- Page header with improved styling -->
    <div class="header">
      <h1 class="headline">Translate Videos While Retaining Original Voice & Natural Lip Sync:</h1>
    </div>

    <!-- Video pop-up container -->
    <div v-if="showVideo" class="video-popup" @click.self="closeVideo">
      <video controls autoplay class="responsive-video">
        <source src="@/assets/original.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <span class="close-btn" @click="closeVideo">&times;</span>
    </div>

    <!-- Sign in page -->
    <div class="content-container">
      <!-- Image that triggers the video popup -->
      <div class="video-preview-container">
        <img src="@/assets/pp.png" alt="Play Video" @click="openVideo" class="play-button-image" />
      </div>
      <!-- <p class="sign-in-text">Get Started:</p> -->
      <button @click="signIn" class="sign-in-button">Get Started</button>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout";
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { firebaseAuth } from '../firebase';

export default {
  components: { DefaultLayout },
  data() {
    return {
      showVideo: false
    };
  },
  methods: {
    signIn() {
      const provider = new GoogleAuthProvider();
      return signInWithPopup(firebaseAuth, provider)
        .then(() => this.$router.replace({ name: 'Account' }));
    },
    openVideo() {
      this.showVideo = true;
    },
    closeVideo() {
      this.showVideo = false;
    }
  }
};
</script>

<style scoped>
/* Overall Page Styles */
body {
  font-family: 'Roboto', sans-serif;
  color: #2c3e50;
  background-color: #f8f9fa;
  margin: 0;
  padding: 0;
}

/* Header Styles */
.header {
  text-align: center;
  margin-top: 25px;
  padding: 10px;
}

.headline {
  font-family: 'Roboto', sans-serif;
  font-size: 1.8rem; /* Adjust the size to your preference */
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 30px;
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


/* Video Popup Styles */
.video-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.85);
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.responsive-video {
  max-width: 80%;
  max-height: 80%;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: white;
  cursor: pointer;
  background: transparent;
  border: none;
  transition: color 0.3s ease;
}

.close-btn:hover {
  color: #ff4c4c;
}

/* Content Container */
.content-container {
  text-align: center;
  margin-top: 20px;
}

.video-preview-container {
  margin-bottom: 20px;
}

.play-button-image {
  cursor: pointer;
  width: 250px;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.play-button-image:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.sign-in-text {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  color: #34495e;
  margin-top: 20px;
  margin-bottom: 15px;
  letter-spacing: 0.5px;
}

/* Sign-in Button Styles */
.sign-in-button {
  font-family: 'Roboto', sans-serif;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.sign-in-button:hover {
  background-color: #2980b9;
  box-shadow: 0 4px 12px rgba(52, 152, 219, 0.5);
}

.sign-in-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.5);
}
</style>
