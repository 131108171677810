<template>
  <div class="row">
    <div class="col">
      <h2>Subscription Plans</h2>

      <hr />

      <div v-for="(product, index) in products" :key="index + '-product'">
        <h3>{{ product.name }}</h3>
        
        <!-- Bullet point list for product description -->
        <ul>
          <li> Clone your natural speaking voice </li> <!-- Adding static description item for illustration -->
          <li> Audio Lip Syncing </li>
          <li> Unlimited Number of Videos</li>
          <li> Upgradble Processsing Speeds</li>
          <li> 30+ Minute Video Duration</li>
          <li> Product Launch Sale: $9.99 / month</li>
        </ul>

        <div
          v-for="(price, priceIndex) in product.prices"
          :key="priceIndex + '-price'"
        >
          <div class="form-check">
            <input
              type="radio"
              class="form-check-input"
              v-model="selectedPrice"
              :value="price.id"
              :id="price.id"
            />
            <label :for="price.id" class="form-check-label">
              {{ price.interval == 'month' ? 'Monthly Plan' : price.interval }}
            </label>
          </div>
        </div>
      </div>

      <button
        class="btn btn-primary"
        @click="createSub"
        :disabled="!selectedPrice || isLoading"
      >
        {{ isLoading ? "Loading..." : "Continue" }}
      </button>
    </div>
  </div>
</template>

<script>
import {
  getFirestore,
  getDocs,
  where,
  query,
  collection,
  addDoc,
  onSnapshot,
} from "firebase/firestore";

import { firebaseAuth } from "@/firebase";

export default {
  data() {
    return {
      products: [],
      selectedPrice: null,
      isLoading: false,
    };
  },
  mounted() {
    this.fetchProducts();
  },
  methods: {
    async fetchProducts() {
      const db = getFirestore();

      const productsRef = collection(db, "products");
      const productsQuery = query(productsRef, where("active", "==", true));
      const productsQuerySnap = await getDocs(productsQuery);

      productsQuerySnap.forEach(async (doc) => {
        const productData = doc.data();

        // Only include the product with the name "Video Translate"
        if (productData.name === "Video Translate" || productData.name === "Video Translate (Year)") {
          const pricesRef = collection(db, "products", doc.id, "prices");
          const pricesQuerySnap = await getDocs(pricesRef);

          // Add a custom description for the product
          const productDescription = "poopoo"; // Custom description

          this.products.push({
            id: doc.id,
            ...productData,
            description: productDescription, // Include the custom description
            prices: pricesQuerySnap.docs.map((price) => {
              return {
                id: price.id,
                ...price.data(),
              };
            }),
          });
        }
      });
    },
    async createSub() {
      this.isLoading = true;
      const db = getFirestore();
      const collectionRef = collection(
        db,
        "customers",
        firebaseAuth.currentUser.uid,
        "checkout_sessions"
      );

      const docRef = await addDoc(collectionRef, {
        price: this.selectedPrice,
        success_url: window.location.origin + '/account',
        cancel_url: window.location.origin,
      });

      onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();

        if (error) {
          console.error(`An error occurred: ${error.message}`);
          this.isLoading = false;
        }

        if (url) {
          window.location.assign(url);
        }
      });
    },
  },
};
</script>
