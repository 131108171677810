<template>
  <div class="page-container">
    <TheNavbar />

    <div class="container mt-3 content-wrap">
      <slot />
    </div>

    <!-- Footer Section -->
    <footer class="footer text-center">
      <p>LRT Labs &copy; {{ new Date().getFullYear() }}</p>
    </footer>
  </div>
</template>

<script>
import TheNavbar from "@/components/TheNavbar";

export default {
  components: { TheNavbar },
};
</script>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the page takes the full height of the viewport */
}

.content-wrap {
  flex: 1; /* Takes up remaining space, pushing the footer down */
}

.footer {
  padding: 0.5rem 0; /* Reduced padding for a sleeker look */
  background-color: #f8f9fa; /* Light grey background */
  color: #6c757d; /* Darker text color */
  text-align: center;
  margin-top: auto; /* Ensure footer is at the bottom */
  font-size: 0.9rem; /* Optional: Smaller font for a more compact look */
}
</style>
